import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import '../../Assets/css/header.css';
import '../../Assets/css/header-media.css';
import { ApiGetNoAuth, ApiPost } from '../../Helpers/Api/ApiData';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../Store/Reducers/Authreducer/userAuth';
import Carthome from '../Cart/Carthome';
import HeaderMenu from './Components/HeaderMenu';
import { toast } from 'react-toastify';

import search_icon from '../../Assets/images/search_icon.webp';
import icon_cart from '../../Assets/images/icon_cart.webp';

let myTimeout;

import ic_my_orders from '../../Assets/images/ic_my_orders.webp';
import ic_wishlist from '../../Assets/images/ic_wishlist.webp';
import ic_my_account from '../../Assets/images/ic_my_account.webp';

/**
 * Header component.
 *
 * @example <Header />
 */
function Header() {
  const [bar, setBar] = useState(false);
  const [active, setActive] = useState();
  const [headerData, setheaderData] = useState([]);
  const [cartDrawer, setCartDrawer] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setsearchResult] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedSearch = useRef(null);
  const cart = useSelector((state) => state.cart);
  const token = useSelector((state) => state.userAuth.token);
  const storeDetail = useSelector((state) => state.store.data);

  const side_open = () => {
    document.body.classList.toggle('menu_open');
  };

  const layer_close_menu = () => {
    setBar(!bar);
    document.documentElement.classList.toggle('cm_overflow');
  };

  /**
   * function for open cart sidebar
   * @return open sidebar of cart
   */
  const handleCartShow = () => {
    setCartDrawer(true);
    document.body.style.overflow = 'hidden';
  };

  /**
   * function for close cart sidebar
   * @return close sidebar of cart
   */
  const handleCartClose = () => {
    setCartDrawer(false);
    document.body.style.overflow = 'unset';
  };

  /**
   * function for toggle menu
   * @return toggle menu of header
   */
  const handleToggleBar = () => {
    setBar(!bar);
    setActive(!active);
    document.documentElement.classList.toggle('cm_overflow');
  };

  /**
   * function for global search
   * @return search data and set reponse data to state
   */
  const getSeachedItems = () => {
    ApiPost('/search', {
      search: searchValue
    })
      .then((response) => {
        console.log(response, 'res');
        setsearchResult(response.data);
        
      })
      .catch(() => setsearchResult([]));
  };

  /**
   * function for logout
   * @return clear redux store.
   */
  const handleLogout = () => {
    dispatch(setLogout());
    handleToggleBar();
  };

  /**
   * function for navigate to login
   * @return navigate to login
   */
  const handleLogIn = () => {
    navigate('/login');
    handleToggleBar();
  };

  /**
   * function for getheader
   * @return set header data to state
   */
  const getHeader = () => {
    ApiGetNoAuth('/get-menu-list?type=1&page_type=1', {})
      .then((response) => {
        if (response?.data?.status) {
          setheaderData(response.data.data);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  /**
   * function for get categories
   * @return set categories to state
   */
  const getCategories = () => {
    if (token) {
      ApiPost('/getCategories')
        .then((response) => {
          if (response?.data?.status) {
            setCategories(response?.data?.data);
          } else {
            toast.error(response?.data?.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  };

  useEffect(() => {
    getHeader();
    getCategories();
  }, [token]);

  useEffect(() => {
    myTimeout = setTimeout(getSeachedItems, 500);
    return () => {
      clearTimeout(myTimeout);
    };
  }, [searchValue]);

  console.log(storeDetail, "storeDetail");

  return (
    <>
      <header className="header_main">
        <div className="header_layer" onClick={layer_close_menu}></div>
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <span className="Welcome"><b>Welcome</b> to our Store {storeDetail?.store_name}</span>
                <a href={`tel: ${storeDetail?.store_phone}`} to="/" className="hedaer-phone">
                  <i className="fa-solid fa-phone phone-icon"></i>{storeDetail?.store_phone}
                </a>

                <span className='m-0 ms-1'>
                  <a href={`mail: ${storeDetail?.store_email}`} to="/" className="ml-md-0 ml-lg-0 ml-xl-5  hedaer-phone">
                    <i className="fa-solid fa-envelope phone-icon"></i>Email Us {storeDetail?.store_email}
                  </a>
                </span  >
              </div>
              {token && (
                <div className="col-md-6 text-end">
                  <Link to="/my_orders" className="order">
                    <img src={ic_my_orders} /> My Orders
                  </Link>
                  <Link to="/wishlist" className="which">
                  <img src={ic_wishlist} /> Whishlist
                  </Link>
                  <Link to="/profile" className="account">
                  <img src={ic_my_account} /> My Account
                  </Link>
                  {/* <a
                    className="account"
                    style={{ cursor: 'pointer' }}
                    onClick={() => dispatch(setLogout())}
                  >
                    <i
                      className="fa fa-sign-out"
                      aria-hidden="true"
                      style={{ marginRight: '4px', color: 'gray' }}
                    ></i>
                    Log Out
                  </a> */}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="middle-header">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-3" style={{ position: 'unset' }}>
                <div
                  className={`menu-wrapper ${bar ? 'show' : 'hide'}`}
                  style={{ display: `${bar ? 'block' : ''}` }}
                >
                  <div
                    className="menu-icon-wrapper"
                    onClick={() => {
                      handleToggleBar();
                    }}
                  >
                    <div className="logo sidebar_logo" onClick={() => navigate('/')}>
                      <div className="logo-img">
                        <img src={storeDetail?.store_logo_image_url} alt="" />
                      </div>
                      <div className="logo-content">
                        <h2 className="bait">{storeDetail?.store_name}</h2>
                        <p className="whole">{storeDetail?.store_owner}</p>
                      </div>
                    </div>
                    <Link className="menu-icon "></Link>
                  </div>
                  <ul className="menu">
                    <li key={'home'}>
                      <Link to="/" onClick={() => handleToggleBar()}>
                        Home
                      </Link>
                    </li>
                    {token && (
                      <li key={'shpCat'} className="hover_open" onClick={side_open}>
                        <Link>
                          Shop by Category
                          <span className="cavet">
                            <i className="fas fa-caret-right"></i>
                          </span>{' '}
                        </Link>
                        <ul className="sub-category">
                          {/* <li>
                            sbchvshdch
                          </li> */}
                          {categories?.map((cat) => (
                            <li key={'Category2'} onClick={() => handleToggleBar()}>
                              <Link to={`/category/${cat?.id}`}>{cat?.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    )}
                    <li key={'Testimonials'} style={{ borderTop: 'none' }}>
                      <Link to="/testimonials" onClick={() => handleToggleBar()}>
                        Testimonials
                      </Link>
                    </li>
                    <li key={'Contact'}>
                      <Link
                        to="/contact"
                        onClick={() => {
                          handleToggleBar();
                        }}
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li key={'About'}>
                      <Link
                        to="/page/about-us"
                        onClick={() => {
                          handleToggleBar();
                        }}
                      >
                        About Us
                      </Link>
                    </li>
                    <li key={'Terms'}>
                      <Link
                        to="/page/terms-conditions"
                        onClick={() => {
                          handleToggleBar();
                        }}
                      >
                        Terms of Use
                      </Link>
                    </li>
                    <li key={'Help'}>
                      <Link
                        to="/helpsupport"
                        onClick={() => {
                          handleToggleBar();
                        }}
                      >
                        Help & Support
                      </Link>
                    </li>
                    {token ? (
                      <li key={'Logout'} className='cursor-pointer' style={{cursor: "pointer"}}>
                        <a onClick={handleLogout}>Logout</a>
                      </li>
                    ) : (
                      <li key={'Logout'} style={{cursor: "pointer"}}>
                        <a onClick={handleLogIn}>Login</a>
                      </li>
                    )}
                  </ul>
                </div>
                <div className="bar-icon wrapper">
                  <Link className="bars">
                    <i
                      className="fa-solid fa-bars"
                      onClick={() => {
                        handleToggleBar();
                      }}
                    ></i>
                  </Link>
                </div>
                <div className="logo" onClick={() => navigate('/')}>
                  <div className="logo-img">
                    <img src={storeDetail?.store_logo_image_url} alt="" />
                  </div>
                  <div className="logo-content">
                    <h2 className="bait">{storeDetail?.store_name}</h2>
                    <p className="whole">{storeDetail?.store_owner}</p>
                  </div>
                </div>
              </div>
              <div className='col-xl-9'>
                  <div className='row justify-content-end'>
                    <div className="col-xl-5 col-md-10">
                      <div className="serch-box">
                        <div className=" searching">
                          <input
                            className="form-control searching"
                            ref={selectedSearch}
                            type="search"
                            placeholder="Search Here…"
                            aria-label="Search"
                            defaultValue={searchValue}
                            onChange={(event) => setSearchValue(event.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                setsearchResult([]);
                                selectedSearch.current.value = "";
                                setSearchValue("");
                                 navigate("/view-all", {state: {search: searchValue, title: `Search For "${searchValue}"`}});
                              }
                          }}
                          />
                          {/* <i
                            style={{ position: 'absolute', right: '12px' }}
                            className="fa-solid fa-magnifying-glass search-icon"
                          ></i> */}
                          <span style={{ position: 'absolute', right: '12px', top: '12px' }}> <img src={search_icon} /> </span>
                        </div>
                        <div className="search_auto">
                        {searchValue && <div className="search_result">
                          <p><Link onClick={() => {
                                      setsearchResult([]);
                                      selectedSearch.current.value = "";
                                      setSearchValue("");
                                    }} to={"/view-all"} state={{search: searchValue, title: `Search For "${searchValue}"`}}>{`Search For "${searchValue}"`}</Link></p>
                          </div>}
                          {searchResult?.category?.map((singleData) => {
                            return (
                              <div className="search_result">
                                {' '}
                                <p className="">
                                  <Link
                                    onClick={() => {
                                      setsearchResult([]);
                                      setSearchValue("");
                                      selectedSearch.current.value = "";
                                    }}
                                    to={`/category/${singleData.id}`}
                                    className='search_item'
                                  >
                                    {' '}
                                    <div>{singleData.category_name}</div>  <div><b>Category</b>  </div>
                                  </Link>
                                </p>{' '}
                              </div>
                            );
                          })}
                          {searchResult?.product?.map((singleData) => {
                            return (
                              <div className="search_result">
                                {' '}
                                <p className="">
                                  <Link
                                    onClick={() => {
                                      setsearchResult([]);
                                      setSearchValue("");
                                      selectedSearch.current.value = "";
                                    }}
                                    to={`/product/${singleData.id}`}
                                    className='search_item'
                                  >
                                    {' '}
                                    <div>{singleData?.product_name}</div>  <div><b>Product</b>  </div>
                                  </Link>
                                </p>{' '}
                              </div>
                            );
                          })}
                          {searchResult?.skuproduct?.map((singleData) => {
                            return (
                              <div className="search_result">
                                {' '}
                                <p className="">
                                  <Link
                                    onClick={() => {
                                      setsearchResult([]);
                                      setSearchValue("");
                                      selectedSearch.current.value = "";
                                    }}
                                    to={`/product/${singleData.id}`}
                                    className='search_item'
                                  >
                                    {' '}
                                    <div>{singleData?.sku}</div>  <div><b>sku</b></div>
                                  </Link>
                                </p>{' '}
                              </div>
                            );
                          })}
                          {searchResult?.mpnproduct?.map((singleData) => {
                            return (
                              <div className="search_result">
                                {' '}
                                <p className="">
                                  <Link
                                    onClick={() => {
                                      setsearchResult([]);
                                      setSearchValue("");
                                      selectedSearch.current.value = "";
                                    }}
                                    to={`/product/${singleData.id}`}
                                    className='search_item'
                                  >
                                    {' '}
                                    <div>{singleData?.mpn_number}</div>  <div><b>MPN</b></div>
                                  </Link>
                                </p>{' '}
                              </div>
                            );
                          })}
                          {searchResult?.brand?.map((singleData) => {
                            return (
                              <div className="search_result">
                                {' '}
                                <p className="">
                                  <Link
                                    onClick={() => {
                                      setsearchResult([]);
                                      setSearchValue("");
                                      selectedSearch.current.value = "";
                                    }}
                                    state={{brand_id: singleData?.id,
                                      title:  singleData?.brand_name}}
                                    to={`/view-all`}
                                    className='search_item'
                                  >
                                    {' '}
                                    <div>{singleData?.brand_name}</div>  <div><b>Brand</b></div>
                                  </Link>
                                </p>{' '}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-2 col-md-2">
                      <div className="shop">
                        {token ? (
                          <button
                            className="shop-btn"
                            onClick={() => {
                              handleCartShow();
                            }}
                          >
                            <span
                              style={{ position: 'relative' }}>
                              <img className='me-2' src={icon_cart} />
                            </span>
                            $ {cart?.totalPrice?.toFixed(2)}
                          </button>
                        ) : (
                          <button className="shop-btn" onClick={() => navigate('/login')}>
                            Login
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="botoom-header">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span>
                  <i className="fa-solid fa-bars"></i>
                </span>
              </button>
              <div className="collapse navbar-collapse" id="main_nav">
                <ul className="navbar-nav">
                  <HeaderMenu headerData={headerData} />
                  {headerData?.length != 0 && (
                    <>
                      <li key={'product'} className="nav-item active">
                        <NavLink className="nav-link" to="/product">
                          Products
                        </NavLink>
                      </li>
                      <li key={'product'} className="nav-item ">
                        <NavLink className="nav-link" to="/testimonials">
                          TESTIMONIALS
                        </NavLink>
                      </li>
                      <li key={'product'} className="nav-item ">
                        <NavLink className="nav-link" to="/contact">
                         Contact Us
                        </NavLink>
                      </li>
                      {/*<li key={"product"} className="nav-item active">*/}
                      {/*  {" "}*/}
                      {/*  <NavLink className="nav-link" to="/blog">*/}
                      {/*    Blogs{" "}*/}
                      {/*  </NavLink>{" "}*/}
                      {/*</li>*/}
                    </>
                  )}
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <Carthome show={cartDrawer} handleCartClose={handleCartClose} />
    </>
  );
}

export default Header;
