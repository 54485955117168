import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../Assets/css/footer.css';
import { ApiGet } from '../../Helpers/Api/ApiData';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

/**
 * Footer component.
 *
 * @example <Footer />
 */
function Footer() {
  const [footerData, setfooterData] = useState([]);
  const token = useSelector((state) => state.userAuth.token);
  const storeDetail = useSelector((state) => state.store.data);

  /**
   * function for getheader
   * @return set header data to state
   */
  const getHeader = () => {
    ApiGet('/get-menu-list?type=2&page_type=1', {})
      .then((response) => {
        if (response?.data?.status) {
          setfooterData(response.data.data);
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  /**
   * function for limit footer
   * @return set header data to state
   */
  const limit6Footer = (footer) => {
    const arr = [];
    for (let i = 0; i < footer?.length; i += 6) {
      arr.push(
        <div className="col-md-2 ms-auto">
           <h3 className='footer_menu_title'>KNOW MORE</h3>
          <ul className="know-more">
            {footer.slice(i, 6 + i).map((data, idx) => {
              return (
                <>
                  <li key={idx + i}>
                    <Link to={`/page/${data.slug}`}>{data.menu_name}</Link>
                  </li>
                </>
              );
            })}
            {/* <li key={'faq'}>
                <a>Return Policy</a>
              </li> */}
              <li key={'faq'}>
                <Link to={`/faq`}>FAQ</Link>
              </li>
          </ul>
        </div>
      );
    }
    return arr;
  };

  useEffect(() => {
    getHeader();
  }, []);
  return (
    <div className="footer footer_main">
      <div className="container">
        <div className="row" style={{ gap: '48px' }}>
          <div className="col-md-6">
            <div className="logo">
              <div className="logo-img">
                <img src={storeDetail?.store_logo_image_url} alt="" />
              </div>
              <div className="logo-content">
                <h2 className="bait">{storeDetail?.store_name}</h2>
                <p className="whole">{storeDetail?.store_owner}</p>
              </div>
            </div>
            <p className="footer-p">
               Buford Bait & Tackles, is a live bait and tackle wholesale distribution company based in Cordele, GA
            </p>
            <div className="social-icon">
              <a href={storeDetail?.facebook_link}> <i className="fa-brands fa-facebook-f facebook" ></i></a>
              <a href={storeDetail?.twitter_link}><i className="fa-brands fa-twitter twitter"></i></a>
              <a href={storeDetail?.youtube_link}><i className="fa-brands fa-youtube you"></i></a>
              {/* <a><i className="fa-brands fa-instagram instagram"></i></a> */}
            </div>
          </div>

          {limit6Footer(footerData)}
          <div className="col-md-2 ms-auto">
            <h3 className='footer_menu_title'>HAPPY TO SERVE YOU</h3>
            <ul className="know-more">
              <li key={'faq'}>
                <Link to={`/contact`}>Contact Us</Link>
              </li>
              {token && <><li >
                <Link to={"/profile"}>Your Account</Link>
              </li>
              <li key={'faq'}>
                <Link to={`/my_orders`}>Your Orders</Link>
              </li></>}
            </ul>
          </div>
        </div>
        <div className="copy">
          <span className="copy-right">@2023, All Copyrights reserved by Buford Bait & Tackle</span>
          {/* <a href="https://nectarbits.com/">Design By Nectarbits Pvt. Ltd.</a> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
