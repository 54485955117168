import { toast } from 'react-toastify';
import {
  setAnimationHide,
  setAnimationShow
} from '../../Store/Reducers/Animationreducer/animation';
import { ApiPost } from '../../Helpers/Api/ApiData';
import { deletCart, overRideCart } from '../../Store/Reducers/Cartreducer/cart';
import { overrideWishList } from '../../Store/Reducers/Whishlistreducer/wishList';

export const handleAddToCart = (id, price, qty, dispatch) => {
  if (qty >= 1) {
    dispatch(setAnimationShow());
    ApiPost('/addCart', {
      product_id: id,
      quantity: qty,
      price: price
    })
      .then((response) => {
        dispatch(setAnimationHide());
        if (response.data.status) {
          ApiPost('/getCartList')
            .then((response) => {
              toast.success('Added To Cart');
              dispatch(
                overRideCart({
                  data: response.data.data,
                  allData: response?.data
                })
              );
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        dispatch(setAnimationHide());
      });
  } else {
    toast.warning('Select At Least One Quantity');
  }
};
export const handleRemoveCart = (id, dispatch) => {
  dispatch(setAnimationShow());
  ApiPost('/deleteCart', {
    cart_id: id
  })
    .then((response) => {
      dispatch(setAnimationHide());
      if (response.data.status) {
        ApiPost('/getCartList')
          .then((response) => {
            toast.success('Removed From Cart');
            dispatch(
              overRideCart({
                data: response.data.data,
                allData: response?.data
              })
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        toast.error(response.data.message);
      }
    })
    .catch((error) => {
      dispatch(setAnimationHide());
      toast.error(error.message);
    });
};
export const handleDeleteCart = (dispatch) => {
  dispatch(setAnimationShow());
  ApiPost('/removeCart')
    .then((response) => {
      dispatch(setAnimationHide());
      if (response.data.status) {
        dispatch(deletCart());
        ApiPost('/getCartList')
          .then((response) => {
            dispatch(
              overRideCart({
                data: response.data.data,
                allData: response?.data
              })
            );
          })
          .catch((error) => {
            toast.error(error.message);
          });
      } else {
        /* toast.error(response.data.message); */
        console.log('Delete Cart');
      }
    })
    .catch((error) => {
      toast.error(error.message);
      dispatch(setAnimationHide());
    });
};

export const handleUpdateCart = (id, qty, price, dispatch) => {
  dispatch(setAnimationShow());
  ApiPost('/updateCart', {
    cart_id: id,
    quantity: qty,
    price: price
  })
    .then((response) => {
      dispatch(setAnimationHide());
      if (response.data.status) {
        ApiPost('/getCartList')
          .then((response) => {
            dispatch(
              overRideCart({
                data: response.data.data,
                allData: response?.data
              })
            );
          })
          .catch((error) => {
            toast.error(error.message);
          });
      } else {
        toast.error(response?.data?.message);
      }
    })
    .catch((error) => {
      toast.error(error.message);
      dispatch(setAnimationHide());
    });
};

export const handleAddToWishlist = (id, dispatch) => {
  dispatch(setAnimationShow());
  ApiPost('/addWhishList', {
    product_id: id
  })
    .then((response) => {
      dispatch(setAnimationHide());
      if (response.data.status) {
        ApiPost('/getWhishList').then((response) => {
          dispatch(overrideWishList({ data: response.data.data }));
          toast.success('Item Added To Wishlist');
        });
      } else {
        toast.error(response?.data?.message);
      }
    })
    .catch((error) => {
      dispatch(setAnimationHide());
      toast.error(error.message);
    });
};
export const handleRemoveFromWishlist = (id, dispatch) => {
  dispatch(setAnimationShow());
  ApiPost('/removeWhishList', {
    productId: id
  })
    .then((response) => {
      // console.log(response)
      if (response.data.status) {
        ApiPost('/getWhishList').then((response) => {
          dispatch(overrideWishList({ data: response.data.data }));
          toast.success('Item Removed From Wishlist');
        });
      } else {
        toast.error(response.data.message);
      }
      dispatch(setAnimationHide());
    })
    .catch((error) => {
      console.log(error);
      toast.error('Error From Server');
      dispatch(setAnimationHide());
    });
};
