import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
// eslint-disable-next-line
import '../../Assets/css/cart-home.css';
import { handleRemoveCart } from '../../Common/Cartoprations/cart';
import trash from '../../Assets/images/cartimages/trash.webp';
import PropTypes from 'prop-types';

/**
 * Carthome component.
 *
 * @example <Carthome {...props} />
 */
function Carthome({ show, handleCartClose }) {
  const cart = useSelector((state) => state.cart.cart);
  const total = useSelector((state) => state.cart.totalPrice);

  const dispatch = useDispatch();

  /**
   * function for remove product from cart
   * @return call handleRemoveCart function
   */
  const handleRemoveFromCart = (product) => {
    console.log(product, 'pd');
    handleRemoveCart(product?.cart_id, dispatch);
  };

  if (!show) return null;

  console.log(cart, 'cart');
  return (
    <>
      <section className="main-popup cart_home_main">
        <div className="container">
          <div id="popup1" className="overlay" onClick={handleCartClose}></div>
          <div className="popup">
            <div className="main-shedule">
              <h1 className="schedule">My Cart</h1>
              <a className="close" onClick={handleCartClose}>
                <i className="fa fa-xmark"></i>
              </a>
            </div>
            {cart?.length == 0 && (
              <>
                <div className="empty_cart">
                  <img
                    src="/images/empty-cart.png"
                    width="130"
                    height="130"
                    className="img-fluid mb-4 mr-3"
                  ></img>
                  <p className="empty_cart_weight">Your Cart is Empty</p>
                  <p>Add something to make me happy </p>
                  <Button colorScheme="blue" p={2} m={3}>
                    <Link to="/product" onClick={handleCartClose} style={{ color: '#224151' }}>
                      {' '}
                      Product{' '}
                    </Link>
                  </Button>
                </div>
              </>
            )}
            {cart?.map((singleItem) => {
              return (
                <div className="box">
                  <div className="box-img">
                    <img src={singleItem?.product_image} alt="" />
                  </div>
                  <div className="box-content">
                    <div className='mpn_number'>MPN : {singleItem?.productData?.mpn_number}</div>
                    <div className='product_name'>{singleItem?.productData?.product_name}</div>
                    <div className='product_price'>{singleItem?.product_qty} X $ {parseFloat(singleItem?.is_special ? singleItem?.productData?.product_flat?.special_price : singleItem?.is_offer ? singleItem?.productData?.offerdata?.offer_price : singleItem?.productData?.product_flat?.price).toFixed(2) }</div>
                    <div className='product_upc'>UPC : {singleItem?.productData?.product_number}</div>
                    <div className='product_availibility'>Availability : {singleItem?.productData?.product_flat?.qty }</div>
                  </div>
                  <div className="icon">
                    <a className='close_btn' onClick={() => handleRemoveFromCart(singleItem)}>
                      <img src={trash} alt="trash" />
                    </a>
                  </div>
                </div>
              );
            })}

            {cart?.length != 0 && (
              <>
                {' '}
                <div className="footer" style={{ backgroundColor: 'white' }}>
                  <h2>Subtotal</h2>
                  <span>${parseFloat(total).toFixed(2)}</span>
                </div>
                <div className="view_home">
                  <Link to="/cart" onClick={handleCartClose} className="view-btn">
                    View Cart
                  </Link>
                </div>{' '}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

Carthome.propTypes = {
  /**
   * show state to show sidebar
   */
  show: PropTypes.bool,
  /**
   * handleCartClose function for close cart sidebar
   */
  handleCartClose: PropTypes.func
};

export default Carthome;
