import axios from 'axios';
import store from '../../Store/store';

// const BASE_URL = process.env.REACT_APP_BASE_URL;

// const BASE_URL = 'https://bbatadminlatest.on-demand-app.com/api';
// const BASE_URL = 'https://admin.bufordbaitandtackle.com/api';


// const url_array =
//     {
//       'localhost:3000':"https://bbatadminlatest.on-demand-app.com/api",
//       'bbat.on-demand-app.com':"https://bbatadminlatest.on-demand-app.com/api",
//       'bufordbaitandtackle.com':"https://admin.bufordbaitandtackle.com/api"
//     };

// const url_array = {
//   'localhost:3000': 'https://admin.bufordbaitandtackle.com/api',
//   'bbat.on-demand-app.com': 'https://bbatadminlatest.on-demand-app.com/api',
//   'bufordbaitandtackle.com': 'https://admin.bufordbaitandtackle.com/api'
// };

// const BASE_URL = url_array[window.location.host];
const BASE_URL = "https://admin.bufordbaitandtackle.com/api";


//default header for all request
const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
  api_key: true
};
export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};
  const token = store.getState().userAuth.token;
  // console.log(token, 'token')
  if (options.hasOwnProperty('isAuth') && options.isAuth) {
    headers['Authorization'] = `Bearer ${token}`;
    headers['Cache-Control'] = 'no-cache';
  }

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json';
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  return { headers };
};
export const getHttpOptionsForUploadWithAuth = (options = defaultHeaders) => {
  let headers = {};
  let token = localStorage.getItem('persist:userAuth');
  token = JSON.parse(JSON.parse(token).token);
  // console.log(token, 'token')

    headers['Authorization'] = `Bearer ${token}`;
    headers['Cache-Control'] = 'no-cache';
  

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json';
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  headers['Content-Type'] = 'multipart/form-data';

  return { headers };
};
export const getHttpOptionsForUpload = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty('isJsonRequest') && options.isJsonRequest) {
    headers['Content-Type'] = 'application/json';
  }

  if (options.hasOwnProperty('AdditionalParams') && options.AdditionalParams) {
    headers = { ...headers, ...options.AdditionalParams };
  }

  // headers["Access-Control-Allow-Origin"] = "*"
  headers['Content-Type'] = 'multipart/form-data';

  /* setting appId as default */
  // headers['appid'] = 'hummz';

  return { headers };
};
export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData)
      .then((responseJson) => {
        console.log(responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        console.log(error);
        reject(error?.response?.data);
      });
  });
};
export const ApiPostNoAuthWithImage = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData, {
        ...getHttpOptionsForUpload()
      })
      .then((responseJson) => {
        // resolve(responseJson);
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error?.response?.data);
      });
  });
};

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + type)
      .then(async (responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BASE_URL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const ApiPost = (type, userData, additionalHeader = {}) => {
  console.log(additionalHeader);
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data);
        } else {
          reject(error?.response?.data);
        }
      });
  });
};
export const ApiPostWithImage = (type, userData, additionalHeader = {}) => {
  console.log(additionalHeader);
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData, {...getHttpOptions(), ...getHttpOptionsForUploadWithAuth()})
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data);
        } else {
          reject(error?.response?.data);
        }
      });
  });
};
export const ApiPostCart = (type, userData, token) => {
  const header = getHttpOptions();
  header.headers.Authorization = `Bearer ${token}`;
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData, header)
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data);
        } else {
          reject(error?.response?.data);
        }
      });
  });
};
// export const ApiPostCart = (type, additionalHeader) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .post(BASE_URL + type, userData, {...getHttpOptions(),...additionalHeader})
//       .then((responseJson) => {
//         resolve(responseJson);
//       })
//       .catch((error) => {
//         if (
//           error &&
//           error.hasOwnProperty("response") &&
//           error.response &&
//           error.response.hasOwnProperty("data") &&
//           error.response.data &&
//           error.response.data.hasOwnProperty("error") &&
//           error.response.data.error
//         ) {
//           reject(error?.response?.data);
//         } else {
//           reject(error?.response?.data);
//         }
//       });
//   });
// };

export const ApiPut = (type, userData) => {
  const ext = 'admin';
  return new Promise((resolve, reject) => {
    axios
      .put(BASE_URL + ext + type, userData)
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error.response.data);
        } else {
          reject(error.response.data);
        }
      });
  });
};

export const ApiUpload = (type, userData, AdditionalHeader) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BASE_URL + type, userData, {
        ...getHttpOptionsForUpload(),
        ...AdditionalHeader
      })
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty('response') &&
          error.response &&
          error.response.hasOwnProperty('data') &&
          error.response.data &&
          error.response.data.hasOwnProperty('error') &&
          error.response.data.error
        ) {
          reject(error?.response?.data);
        } else {
          reject(error?.response?.data);
        }
      });
  });
};
