import {
  Box,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Stack,
  VStack
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiGetNoAuth } from '../../Helpers/Api/ApiData';
import Blogsection from '../Blogs/Blogsection';
import { toast } from 'react-toastify';

/**
 * HomeCMS component.
 *
 * @example <HomeCMS />
 */
function HomeCMS() {
  const { id } = useParams();
  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    ApiGetNoAuth(`/get-cms?slug=${id}`)
      .then((response) => {
        if (response?.data?.status) {
          setIsLoading(false);
          setContent(response.data.data.content);
        } else {
          setIsLoading(false);
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => toast.error(error?.message));
  }, [id]);
  if (isLoading) {
    return (
      <Box padding="6">
        {/* <SkeletonCircle size='10' /> */}
        <SkeletonText m="4" noOfLines={1} spacing="4" skeletonHeight="300" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <SkeletonText m="4" noOfLines={1} spacing="4" skeletonHeight="300" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
        <Skeleton m={2} height="20px" />
      </Box>
    );
  }
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: content }}></div>
      {/* {
        id == 'home' && <Blogsection />
      } */}
    </>
  );
}

export default HomeCMS;
