import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchProduct: []
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.searchProduct = action.payload.data;
    }
  }
});
export const { setSearch } = productSearchSlice.actions;
export default productSearchSlice.reducer;
