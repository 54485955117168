import React from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
function Protected({ token, children, path }) {
  console.log(path);
  if (!token) {
    toast.warn('Please Login To Access This Page ');
    return <Navigate to="/login" replace />;
  }
  return children;
}
export default Protected;
