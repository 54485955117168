import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * HeaderMenu component.
 *
 * @example <HeaderMenu />
 */
function HeaderMenu({ headerData }) {
  const navigate = useNavigate();

  /**
   * function for toggle header menu
   * @return toggle menu of header
   */
  const handleToggle = () => {
    document.documentElement.classList.toggle('header_active');
    console.log('adjbjhcbj');

  };

  const handleToggleBar = () => {
    document.documentElement.classList.remove('header_active');
  };

  console.log("first", headerData);
  return (
    <>
      {headerData?.map((singleData, idx) => {
        return singleData.sub_menulist.length == 0 ? (
          <li key={idx} className="nav-item active">
            {' '}
            <NavLink className="nav-link" to={`/page/${singleData.slug}`}>
              {singleData.menu_name}{' '}
            </NavLink>{' '}
          </li>
        ) : (
          <li
            onClick={handleToggle}
            className="nav-item dropdown has-megamenu"
          >
            <div className='header_layer1' onClick={handleToggleBar}></div>
            <a
              className="nav-link dropdown-toggle "
              id={`${singleData?.menu_name}_droapDown`}
              data-bs-toggle="dropdown"
            >
              {singleData?.menu_name}
              <div className="dropdown-menu header_block" id={singleData?.menu_name} role="menu">
                <div className="r">
                  <div className="col-lg-3 col-6">
                    <div className="col-megamenu">
                      {/* <h6 className="title">Title Menu One</h6> */}
                      <ul className="list-unstyled">
                        {singleData.sub_menulist?.map((submenu, idx) => {
                          return (
                            <li key={idx} className="nav-item">
                              {' '}
                              <NavLink
                                onClick={() => navigate(`/page/${submenu.slug}`)}
                                className="nav-link"
                                to={`/page/${submenu.slug}`}
                              >
                                {submenu.menu_name}{' '}
                              </NavLink>{' '}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </>
  );
}

HeaderMenu.propTypes = {
  /**
   * headerData array to show header menu
   */
  headerData: PropTypes.array
};

export default HeaderMenu;
