import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAnimation: false
};

export const animationSlice = createSlice({
  name: 'animation',
  initialState,
  reducers: {
    setAnimationShow: (state, action) => {
      console.log(action);
      state.isAnimation = true;
    },
    setAnimationHide: (state, action) => {
      state.isAnimation = false;
      console.log(action);
    }
  }
});
export const { setAnimationShow, setAnimationHide } = animationSlice.actions;
export default animationSlice.reducer;
