import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomeCMS from '../../Pages/CMS/HomeCMS';
import { routeData } from '../routeData';
import Protected from '../Protectedroute';

/**
 * Publicroutes component.
 *
 * @example <Publicroutes />
 */
function Publicroutes() {
  const token = useSelector((state) => state.userAuth.token);
  return (
    <>
      <Routes>
        {routeData?.map((route) => {
          return route?.authorize ? (
            <Route
              path={route?.path}
              element={
                <Protected token={token} path={route?.path}>
                  {route?.component}{' '}
                </Protected>
              }
            ></Route>
          ) : (
            <Route path={route?.path} element={route?.component}></Route>
          );
        })}
        <Route exact path="*" element={<Navigate replace to="/page/Home" />} />
      </Routes>
    </>
  );
}

export default Publicroutes;
