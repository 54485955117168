import React from 'react';
import { Spinner } from 'react-bootstrap';

function DetailsLoader() {
  return (
    <>
      <div className="spiiner_In_details">
        {' '}
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.800" size="xl" />
      </div>
    </>
  );
}

export default DetailsLoader;
