import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wishlist: []
};

const isContain = (id, oldData) => {
  for (let i = 0; i < oldData.length; i++) {
    if (oldData[i].id === id) return true;
  }
  return false;
};

export const wishListSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addToWishlist: (state, action) => {
      if (!isContain(action.payload.data.id, state.wishlist)) {
        state.wishlist = [...state.wishlist, action.payload.data];
      }
    },
    deletList: (state) => {
      state.wishlist = [];
    },
    removeFromWishlist: (state, action) => {
      state.wishlist = state.wishlist.filter(
        (data) => data.product_id !== action.payload.data.product_id
      );
    },
    overrideWishList: (state, action) => {
      state.wishlist = action.payload.data;
    }
  }
});
export const { addToWishlist, removeFromWishlist, overrideWishList, deletList } =
  wishListSlice.actions;
export default wishListSlice.reducer;
