import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Assets/css/style.css';
import './Assets/css/media.css';
import './Assets/css/bootstrap.min.css';
import './Assets/css/bootstrap.min.css.map';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'swiper/css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import 'font-awesome/css/font-awesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import store from './Store/store';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import './Assets/css/ambiente.css';
import './Assets/css/azienda.css';
import './Assets/css/certificazioni.css';
import './Assets/css/etica.css';
import './Assets/css/governance.css';
import './Assets/css/header_footer.css';
import './Assets/css/news.css';
import './Assets/css/styleCMS.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DetailsLoader from './Components/Loader/DetailsLoader';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ChakraProvider>
            <Suspense
              fallback={
                <>
                  <DetailsLoader />
                </>
              }
            >
              <App />
            </Suspense>
          </ChakraProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
