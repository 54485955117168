import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { ApiGet, ApiPost } from './Helpers/Api/ApiData';
import Footer from './Layouts/Footers/Footer';
import Header from './Layouts/Headers/Header';
import Publicroutes from './Routes/Publicroutes/Publicroutes';
import { overRideCart } from './Store/Reducers/Cartreducer/cart';
import { overrideWishList } from './Store/Reducers/Whishlistreducer/wishList';
import { Progress } from '@chakra-ui/react';
import { setStoreDetail } from './Store/Reducers/Storereducer/storeData';
// import { setLogin } from './Store/Reducers/Authreducer/userAuth';

/**
 * App component.
 *
 * @example <App />
 */
function App() {
  const token = useSelector((state) => state.userAuth.token);
  const animation = useSelector((state) => state.animation.isAnimation);
  const storeDetail = useSelector((state) => state.store.data);
  const dispatch = useDispatch();

  /**
   * get cart items
   * @return set cart item to global state
   */
  const getCartItems = () => {
    ApiPost('/getCartList')
      .then((response) => {
        console.log('Data in cartlist', response);
        if (response?.data?.status) {
          dispatch(
            overRideCart({
              data: response?.data?.data || [],
              allData: response?.data
            })
          );
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  /**
   * get wishlist items
   * @return set wishlist item to global state
   */
  const getWishList = () => {
    ApiPost('/getWhishList')
      .then((response) => {
        if (response?.data?.status) {
          dispatch(overrideWishList({ data: response?.data?.data || [] }));
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((error) => toast.error(error?.message));
  };

  const getStoreData = () => {
    ApiGet('/get-store-setting-data').then((response) => {
      console.log("response", response);
      dispatch(setStoreDetail(response?.data?.data));
    }).catch((err) => {
      console.log(err);
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (token) {
        getCartItems();
        getWishList();
      }
      getStoreData();
    }, 200);
  }, [token]);

  useEffect(() => {
    if (storeDetail?.store_favicon_image_url) {
     let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = storeDetail?.store_favicon_image_url;
 
    document.title = storeDetail?.store_name || 'Default title';

  }
  }, [storeDetail?.store_favicon_image_url]);

  // dispatch(setLogin({ token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiN2VmMDlmZGM5YjljNGZhYTE2ZWU4OTRmOGIyNTgzM2QxNzQzMjVkMzU5YTRmOTdkNDRiMGQ4NmViNDU2NThiNjMyZjhjNmI1YjNjMmNjNGIiLCJpYXQiOjE2ODk4Mzg4MjguMTIwNTkxLCJuYmYiOjE2ODk4Mzg4MjguMTIwNTk1LCJleHAiOjE3MjE0NjEyMjguMTE3MTIsInN1YiI6IjE3OSIsInNjb3BlcyI6W119.farbbi4h3w6JkOU0ZG5yUhTQDfyCLRArmnWbtsrYT3ZyqG2ZLq01EgZXz2uASrI1zNhp5TS5HWZ2jOpafJ6Tw7GdbNFS1LkXeLp4JhazZ77eNHA55Pr1KKf2hEd2RwAx6c4-tl_APlzT4fWhXG9SNEhPDAa1Oy10erXcb2hWtAAzBLq_Q12KwvJBozEUcwizZonCjZPVWItv3zdQPUF180f1eenfl9LC7flqabKAi7NECKCs3LIL12QiNx0-esd2w_HNlinceFPXmjt84fHf_4_v_vY_yXuLphdAFwuOIJyAAkCVAowkhXr5HlJDE8_aC-ndrXh2x52TPZm5pctmBvDDxSCL4WiGaZTdUg-Z_MT7EF8eJLmU9OVT8XoO1Blh3ElotABPl_xOF9ymqfXVSyYN21Y7NI6BFkGW2BexeYROcE730RrPPRshhWTCA2qWvpwSTxvt4y58vPO3VMWP1OF4ARrIbq78dBFyEyNPiYv6q3Brwqr-3bDvvv3nEMYefTnENQ6PpUInwE_0bT6IDjZWvfXsJbSX37zxiHQ2cd-4itxdE2c5Ub0-yBBendw2LZvlt0AxNwy30_xz0i2HU2nspD3tv75g5JlrZL7WxeZAtsFvszerWuwx1xuEV90Rkt-LHsc2hPTwu2n93CQOmYkoA2hQg3EY4VpI7rjtSBw" }));
  return (
    <>
      <Progress
        hasStripe
        isAnimated
        display={!animation ? 'none' : 'block'}
        isIndeterminate
        position={'sticky'}
        top="0"
        zIndex={112}
        width="100%"
      />
      <Header />
      <Publicroutes />
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default App;
