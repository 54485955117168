import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: [],
  totalPrice: 0,
  allDetail: {}
};

const isContain = (id, oldData) => {
  for (let i = 0; i < oldData.length; i++) {
    if (oldData[i].id === id) return true;
  }
  return false;
};
const calTotalPrice = (data) => {
  console.log(data, 'cartData');
  let totalPrice = 0;
  for (let i = 0; i < data.length; i++) {
    totalPrice += data[i].total_price;
  }
  return totalPrice;
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const { data, quantity } = action.payload;
      if (!isContain(data.product_id, state.cart)) {
        state.cart = [...state.cart, { ...data }];
        state.totalPrice += data.price * quantity;
      }
    },
    overRideCart: (state, action) => {
      console.log(action, 'test');
      const cart = action.payload.data;
      state.cart = cart;
      state.totalPrice = calTotalPrice(cart);
      state.allDetail = action.payload.allData;
    },
    deletCart: (state) => {
      state.cart = [];
      state.totalPrice = 0;
    },
    removeFromCart: (state, action) => {
      state.cart = state.cart.filter((data) => data.product_id !== action.payload.data.product_id);
      state.totalPrice -= action.payload.data.cart_price * action.payload.data.cart_qty;
    },
    increaseQty: (state, action) => {
      const id = action.payload.id;
      state.cart = state.cart.map((singleItem) => {
        if (singleItem.product_id === id) {
          state.totalPrice += singleItem.cart_price;
          return { ...singleItem, cart_qty: singleItem.cart_qty + 1 };
        } else {
          return singleItem;
        }
      });
    },
    updateCart: (state, action) => {
      const { data, quantity } = action.payload;
      state.cart = state.cart.map((single) => {
        if (single.product_id === data.product_id) {
          // prettier-ignore
          state.totalPrice =
            state.totalPrice -
              (data.price * data.cart_qty) +
              (data.price * quantity);
          return { ...single, cart_qty: quantity };
        } else {
          return single;
        }
      });
    },
    decreaseQty: (state, action) => {
      const id = action.payload.id;
      state.cart = state.cart.map((singleItem) => {
        if (singleItem.product_id === id) {
          state.totalPrice -= singleItem.cart_price;
          return { ...singleItem, cart_qty: singleItem.cart_qty - 1 };
        } else {
          return singleItem;
        }
      });
    }
  }
});
export const {
  addToCart,
  overRideCart,
  removeFromCart,
  increaseQty,
  decreaseQty,
  deletCart,
  updateCart
} = cartSlice.actions;
export default cartSlice.reducer;
