import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userAuth from './Reducers/Authreducer/userAuth';
import productSearch from './Reducers/Searchreducer/productSearch';
import wishList from './Reducers/Whishlistreducer/wishList';
import cartUser from './Reducers/Cartreducer/cart';
import animation from './Reducers/Animationreducer/animation';
import { enableMapSet } from 'immer';

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storeData from './Reducers/Storereducer/storeData';

enableMapSet();
const persistConfigAuth = { key: 'userAuth', storage, version: 1 };
const persistConfigWishlist = { key: 'userWishlist', storage, version: 1 };
const persistConfigCart = { key: 'cart', storage, version: 1 };
const persistConfigStore = { key: 'store', storage, version: 1 };

const persistedReducerAuth = persistReducer(persistConfigAuth, userAuth);
const persistedReducerWishlist = persistReducer(persistConfigWishlist, wishList);
const persistedReducerCart = persistReducer(persistConfigCart, cartUser);
const persistedReducerStore = persistReducer(persistConfigStore, storeData);

const combineReducer = combineReducers({
  userAuth: persistedReducerAuth,
  productSearch,
  wishList: persistedReducerWishlist,
  cart: persistedReducerCart,
  store: persistedReducerStore,
  animation
});
const store = configureStore({
  reducer: combineReducer,
  //prettier-ignore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: { ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]}
    })
});
export default store;
