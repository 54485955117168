import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null
};

export const storeSlice = createSlice({
  name: 'storeData',
  initialState,
  reducers: {
    setStoreDetail: (state, action) => {
      state.data = action.payload;
    }
  }
});
export const { setStoreDetail } = storeSlice.actions;
export default storeSlice.reducer;
