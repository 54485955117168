import { lazy } from 'react';
// import HomeCMS from "../Pages/page/HomeCMS";
// import Categories from "../Pages/Categories/Categories";
// import Subcategory from "../Pages/Categories/Subcategory";
// import Contactus from "../Pages/Contact/Contactus";
// import Myorders from "../Pages/Myorders/Myorders";
// import Ordersuccess from "../Pages/Order/Ordersuccess";
// import Product from "../Pages/Product/Product";
// import Productdetails from "../Pages/Productdetails/Productdetails";
// import Viewall from "../Pages/Viewall/Viewall";
// import Cart from "../Pages/Cart/Cart";
// import Wishlist from "../Pages/Wishlist/Wishlist";
// import Profile from "../Pages/Profile/Profile";
// import Testimonials from "../Pages/Testimonials/Testimonials";
// import Faq from "../Pages/FAQ/Faq";
// import Login from "../Pages/Auth/Login";
// import Loginotp from "../Pages/Auth/Loginotp";
// import Register from "../Pages/Auth/Register";
// import Blogdetails from "../Pages/Blogs/Blogdetails";
// import Blogs from "../Pages/Blogs/Blogs";

const Blogs = lazy(() => import('../Pages/Blogs/Blogs'));
const Blogdetails = lazy(() => import('../Pages/Blogs/Blogdetails'));
const HomeCMS = lazy(() => import('../Pages/CMS/HomeCMS'));
const HomePage = lazy(() => import('../Pages/CMS/HomePage'));
const Categories = lazy(() => import('../Pages/Categories/Categories'));
const Subcategory = lazy(() => import('../Pages/Categories/Subcategory'));
const Contactus = lazy(() => import('../Pages/Contact/Contactus'));
const Myorders = lazy(() => import('../Pages/Myorders/Myorders'));
const Ordersuccess = lazy(() => import('../Pages/Order/Ordersuccess'));
const Product = lazy(() => import('../Pages/Product/Product'));
const Productdetails = lazy(() => import('../Pages/Productdetails/Productdetails'));
const Viewall = lazy(() => import('../Pages/Viewall/Viewall'));
const Cart = lazy(() => import('../Pages/Cart/Cart'));
const Wishlist = lazy(() => import('../Pages/Wishlist/Wishlist'));
const Profile = lazy(() => import('../Pages/Profile/Profile'));
const Testimonials = lazy(() => import('../Pages/Testimonials/Testimonials'));
const Faq = lazy(() => import('../Pages/FAQ/Faq'));
const Register = lazy(() => import('../Pages/Auth/Register'));
const Loginotp = lazy(() => import('../Pages/Auth/Loginotp'));
const Login = lazy(() => import('../Pages/Auth/Login'));
const OrderDetail = lazy(() => import('../Pages/Order/OrderDetails'));

const HelpSupport = lazy(() => import('../Pages/HelpSupport/HelpSupport'));

export const routeData = [
  {
    path: '/login',
    component: <Login />,
    authorize: false
  },
  {
    path: '/login-otp',
    component: <Loginotp />,
    authorize: false
  },
  {
    path: '/register',
    component: <Register />,
    authorize: false
  },
  {
    path: '/page/:id',
    component: <HomeCMS />,
    authorize: false
  },
  {
    path: '/helpsupport',
    component: <HelpSupport />,
    authorize: true
  },
  {
    path: '/blog',
    component: <Blogs />,
    authorize: true
  },
  {
    path: '/blogdetails/:id',
    component: <Blogdetails />,
    authorize: true
  },
  {
    path: '/contact',
    component: <Contactus />,
    authorize: true
  },
  {
    path: '/faq',
    component: <Faq />,
    authorize: true
  },
  {
    path: '/testimonials',
    component: <Testimonials />,
    authorize: true
  },
  {
    path: '/profile',
    component: <Profile />,
    authorize: true
  },
  {
    path: '/wishlist',
    component: <Wishlist />,
    authorize: true
  },
  {
    path: '/cart',
    component: <Cart />,
    authorize: true
  },
  /* {
    path: "/cartNew",
    component: <CartNew />,
    authorize: true,
  }, */
  {
    path: '/category',
    component: <Categories />,
    authorize: true
  },
  {
    path: '/category/:id',
    component: <Subcategory />,
    authorize: true
  },
  {
    path: '/product',
    component: <Product />,
    authorize: true
  },
  {
    path: '/product/:id',
    component: <Productdetails />,
    authorize: true
  },
  {
    path: '/view-all',
    component: <Viewall />,
    authorize: true
  },
  {
    path: '/order-success/:id',
    component: <Ordersuccess />,
    authorize: true
  },
  {
    path: '/my_orders',
    component: <Myorders />,
    authorize: true
  },
  {
    path: '/order-detail/:id',
    component: <OrderDetail />,
    authorize: true
  },
  {
    path: '/',
    component: <HomePage />,
    authorize: false
  }
];
